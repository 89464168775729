import { StyledLabel } from "components/Moves/Move";
import { useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Input } from "elements/Input";
import Table from "react-bootstrap/Table";

export default function Leaderboard() {
  const [list, setList] = useState<any>([]);
  const [editIndex, setEditIndex] = useState<number>(-1);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const onSubmit: any = (data: any) => {
    const updatedList = addOrUpdateList(list, data);
    const updatedRanks = updateRanks(updatedList);
    setList(updatedRanks);
  };

  const addOrUpdateList = (list: any, data: any) => {
    const present = list.findIndex((pl: any) => pl.player === data.player);
    if (present !== -1) {
      list[present] = data;
    } else {
      list = [...list, data];
    }
    return list;
  };

  const updateRanks = (list: any) => {
    const recordsWithTotal = list.map((p: any) => {
      return {
        ...p,
        total_score: +p.day_one_score + +p.day_two_score + +p.day_three_score,
      };
    });
    const sortedRecs = recordsWithTotal.sort((a: any, b: any) => {
      debugger;
      return a.total_score > b.total_score ? -1 : 1;
    });
    return sortedRecs;
  };

  const editPlayerRecord = (player: any, index: number) => {
    setValue("player", player.player);
    setValue("day_one_score", player.day_one_score);
    setValue("day_two_score", player.day_two_score);
    setValue("day_three_score", player.day_three_score);
    setEditIndex(index);
  };

  return (
    <div className="leaderboard-container">
      <div className="leaderboard-heading">Create Leaderboard</div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row style={{ minHeight: "8rem" }}>
          <Col md="auto" style={{ minWidth: "19rem" }}>
            <StyledLabel>Team</StyledLabel>
            <Input
              {...register("player", {
                required: true,
                maxLength: 30,
                pattern: /^[A-Za-z0-9 ]+$/i,
              })}
              maxLength={30}
              placeholder="Team"
            />
            {errors.player && <p>A team name is required</p>}
          </Col>
          {/* <Col md="auto" style={{ minWidth: "19rem" }}>
            <StyledLabel>Rank</StyledLabel>
            <input
              type="number"
              placeholder="rank"
              {...register("rank", { required: true, max: 20, min: 0 })}
            />
          </Col>
          <Col md="auto" style={{ minWidth: "19rem" }}>
            <StyledLabel>Day 1 Score</StyledLabel>
            <input
              type="number"
              placeholder="Day One Score"
              {...register("day_one_score", { required: true, min: 0 })}
            />
          </Col> */}
          <Col md="auto" style={{ minWidth: "19rem" }}>
            <StyledLabel>Day 1 Score</StyledLabel>
            <input
              type="number"
              placeholder="Day 1 Score"
              {...register("day_one_score", { required: true, min: 0 })}
            />
          </Col>
          <Col md="auto" style={{ minWidth: "19rem" }}>
            <StyledLabel>Day 2 Score</StyledLabel>
            <input
              type="number"
              placeholder="Day 2 Score"
              {...register("day_two_score", { required: true, min: 0 })}
            />
          </Col>
          <Col md="auto" style={{ minWidth: "19rem" }}>
            <StyledLabel>Day 3 Score</StyledLabel>
            <input
              type="number"
              placeholder="Day 3 Score"
              {...register("day_three_score", { required: true, min: 0 })}
            />
          </Col>
        </Row>
        <Button type="submit" variant="outline-success" style={{ minWidth: "12rem" }}>
          Update
        </Button>
      </form>

      <div className="leaderboard-heading">Leaderboard</div>
      <div>
        <Table bordered>
          <thead>
            <tr className='tableHeader'>
              <th>Rank</th>
              <th>Team</th>
              <th>Day 1 Score</th>
              <th>Day 2 Score</th>
              <th>Day 3 Score</th>
              <th>Total Score</th>
              <th>Edit</th>
            </tr>
          </thead>
          <tbody>
            {list.map((player: any, index: number) => {
              return (
                <tr className={editIndex === index ? "being-edited" : ""}>
                  <td>{index + 1}</td>
                  <td>{player.player}</td>
                  <td>{player.day_one_score}</td>
                  <td>{player.day_two_score}</td>
                  <td>{player.day_three_score}</td>
                  <td>{player.total_score}</td>
                  <td>
                    <button
                      onClick={(e) => {
                        editPlayerRecord(player, index);
                      }}
                    >
                      Edit
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </div>
  );
}
