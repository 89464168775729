import { useHistory } from "react-router";
import { ReactElement, useCallback, useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { GameInfo, Player } from "../../types/API/WarGamingAPI";
import { useDispatch } from "react-redux";
import { reset } from "../../store/apiSlice";
import { getValueChild } from "../../db/firebase";

const StyledLabel = styled.label`
  display: block;
`;

export default function Home(): ReactElement {
  const { register } = useForm<any>({});

  const dispatch = useDispatch();

  const [gameId, setGameId] = useState<string>("");
  const [game, setGame] = useState<GameInfo>();
  const [gameIdError, setGameIdError] = useState<string>("");

  const [players, setPlayers] = useState<Player[]>([]);

  const history = useHistory();
  const goToUrl = (url: string) => history.push(url);

  const getPlayersData = useCallback(async () => {
    if (!gameId.length) {
      setPlayers([]);
      setGameIdError("Please input a game id");
      return;
    }
    const pathToGame = `games/${gameId}`;
    const game = await getValueChild(pathToGame);

    if (!game) {
      setPlayers([]);
      return setGameIdError("This game id does not exist!!");
    }

    setGame(game);
    setGameIdError("");

    //get players for the current game
    const playersPath: string = `games/${gameId}/players`;
    const playersValue = await getValueChild(playersPath);
    if (playersValue) {
      setPlayers(playersValue);
    } else {
      setPlayers([]);
    }
  }, [gameId]);

  useEffect(() => {
    dispatch(reset());
  }, [dispatch]);

  return (
    <div className="homePageContainer h-screen w-100 position-relative h-100 bg-black ">
      <Container fluid className="h-100 position-relative z-index-2">
        <Row className="h-100 g-0 w-100">
          <Col className="justify-content-center justify-items-center d-flex align-items-center">
            <div className="d-flex flex-column">
              <h1 className="custom-header">Time to get started!</h1>
              <span className="body-medium">
                In this game you will bring data-driven precision and speed into
                Digital Activation
              </span>

              <span className="body-small">
                To start the game, please enter the Game ID provided by your
                facilitator and select your team.
              </span>
            </div>
          </Col>
          <Col className="justify-content-center justify-items-center d-flex align-items-center">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                getPlayersData();
              }}
              className="bg-white p-4 rounded-lg  w-100"
            >
              <Row style={{ minHeight: "8rem" }} className="w-100">
                <Col md="auto" className="w-100">
                  <StyledLabel htmlFor="gameIDField">Enter Game ID</StyledLabel>
                  <input
                    type="text"
                    id="gameIDField"
                    {...register("gameIDField")}
                    value={gameId}
                    onChange={(e) => setGameId(e.target.value)}
                  ></input>
                  <Button
                    type="button"
                    variant="success"
                    style={{ minWidth: "12rem" }}
                    onClick={() => getPlayersData()}
                  >
                    Enter
                  </Button>
                  {gameIdError.length ? (
                    <p className="reqFieldMsg"> {gameIdError} </p>
                  ) : null}
                </Col>
              </Row>
              {Boolean(gameId.length) && Boolean(players.length) && (
                <Row style={{ minHeight: "8rem" }}>
                  <Col md="auto" style={{ minWidth: "700px" }}>
                    <StyledLabel>Select Team</StyledLabel>
                    <select
                      {...register("playerNames")}
                      onChange={(e) => {
                        e.preventDefault();

                        game?.currentRound === 0
                          ? goToUrl(`/moves/${gameId}/team/${e.target.value}`)
                          : goToUrl(`/game/${gameId}/team/${e.target.value}`);
                      }}
                    >
                      <option>Select Team</option>
                      {players.map(({ id }, i) => (
                        <option key={i} value={i}>
                          {id}
                        </option>
                      ))}
                    </select>
                  </Col>
                </Row>
              )}
            </form>
          </Col>
        </Row>
      </Container>
      <div className="h-100 position-absolute w-100 collage-background"></div>
    </div>
  );
}
