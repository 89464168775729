import { ref, remove } from "firebase/database";
import { db, getValueChild } from "../../db/firebase";
import { useCallback, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import {
  Game,
  Move,
  ACOMove,
  HTFMove,
  PlayerRound,
  Type,
  GameTypeEnum,
  Status,
  Player,
  AcoDefaultSetup,
} from "../../types/API/WarGamingAPI";
import { ACOformLabelMappings } from "./Utils";
import {
  updatePlayerGameStatus,
  updatePlayerRoundStatus,
} from "../../db/db-helpers";

import { HTFformLabelMappings } from "./Utils";

type Props = {
  movesData: any;
  getMoveByIDData: any;
  getShowAddMoveForm: any;
  gameInfo: Game;
  currentPlayer: undefined | Player;
  disable: boolean;
  moveByIDData: any;
};

export default function DisplayMoves(props: Props) {
  let { gameId, playerId } = useParams<{ gameId: string; playerId: string }>();

  const [moves, setMoves] = useState<
    { round: number; moves: (Move | ACOMove | HTFMove)[] }[]
  >([]);

  const showMovesData = useCallback(async () => {
    const roundsPath: string = `games/${gameId}/players/${playerId}/rounds`;
    const roundsDb = await getValueChild(roundsPath);
    const rounds: PlayerRound[] = roundsDb || [];

    const newMoveState: {
      round: number;
      moves: (Move | ACOMove | HTFMove)[];
    }[] = rounds.map((round, index) => {
      let moveData: (Move | ACOMove | HTFMove)[] = [];

      if (typeof round !== "string" && round?.moves) {
        for (const moveId of Object.keys(round!.moves)) {
          moveData.push(round!.moves[moveId]);
        }
      }

      return {
        round: index,
        moves: moveData,
      };
    });

    newMoveState.sort((a, b) => b.round - a.round);

    setMoves(newMoveState);
  }, [gameId, playerId]);

  useEffect(() => {
    if (props.movesData) {
      showMovesData();
    }
  }, [props.movesData, showMovesData, props.gameInfo]);

  useEffect(() => {
    if (
      !props.gameInfo.players[+playerId].rounds[props.gameInfo.currentRound] ||
      !props.gameInfo.players[+playerId].rounds[
        props.gameInfo.currentRound
      ].hasOwnProperty("status") ||
      props.gameInfo.players[+playerId].rounds[props.gameInfo.currentRound]
        .status === Status.Working
    ) {
    } else {
    }
  }, [props.currentPlayer]);

  const deleteMove = async (moveId: string) => {
    if (moveId) {
      const moveDb = ref(
        db,
        `games/${gameId}/players/${playerId}/rounds/${props.gameInfo.currentRound}/moves/${moveId}`
      );

      await updatePlayerGameStatus({
        gameId,
        playerId,
        status: Status.Working,
      });

      await updatePlayerRoundStatus({
        gameId,
        playerId,
        status: Status.Working,
        currentRound: props.gameInfo.currentRound,
      });

      remove(moveDb);
      showMovesData();
    }
  };

  const editMove = async (moveId: string) => {
    if (moveId) {
      const movePath = `games/${gameId}/players/${playerId}/rounds/${props.gameInfo.currentRound}/moves/${moveId}`;

      const moveDb = await getValueChild(movePath);

      if (moveDb) {
        const gameData: Game = moveDb;

        if (gameData) {
          props.getMoveByIDData(moveDb);
        }
      }

      props.getShowAddMoveForm(true);

      await updatePlayerGameStatus({
        gameId,
        playerId,
        status: Status.Working,
      });

      await updatePlayerRoundStatus({
        gameId,
        playerId,
        status: Status.Working,
        currentRound: props.gameInfo.currentRound,
      });
    }
  };

  const updateStatus = async () => {
    try {
      if (props.gameInfo.currentRound === AcoDefaultSetup.numberOfRounds - 1) {
        await updatePlayerGameStatus({
          gameId,
          playerId,
          status: Status.Ready,
        });
      }

      await updatePlayerRoundStatus({
        gameId,
        playerId,
        status: Status.Ready,
        currentRound: props.gameInfo.currentRound,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const renderACOContent = (_move: Move | ACOMove | HTFMove) => {
    const move = _move as ACOMove;
    const channel = `${move.channel}` as keyof typeof ACOformLabelMappings;
    const landingPage =
      `${move.landingPage}` as keyof typeof ACOformLabelMappings;
    const optimisationKPI =
      `${move.optimisationKPI}` as keyof typeof ACOformLabelMappings;
    const adFormat = `${move.adFormat}` as keyof typeof ACOformLabelMappings;

    return (
      <div>
        You are spending $<b>{move.investment}</b> on{" "}
        <b>{ACOformLabelMappings[channel]}</b>, destination is{" "}
        <b>{ACOformLabelMappings[landingPage]}</b>,{" "}
        <b>{ACOformLabelMappings[optimisationKPI]}</b> as optimisation KPI and{" "}
        <b>{ACOformLabelMappings[adFormat]}</b> as creative option.
      </div>
    );
  };

  const renderHTFContent = (_move: Move | ACOMove | HTFMove) => {
    const move = _move as Move;
    var segmentMove = "Young Families";
    var destination = "Brand Website - Product Range Page";
    let type: string = move.type;

    if (move.type === Type.Price) {
      type = `price customers will pay`;
    }

    if (move.segment === "young_singles") {
      segmentMove = "Young Singles";
    } else if (move.segment === "established_families") {
      segmentMove = "Established Families";
    }

    if (move.landingPage === "website_product_page") {
      destination = "Brand Website - Product Page";
    } else if (move.landingPage === "amazon_product_page") {
      destination = "Amazon Product Page";
    }

    return (
      <div>
        Campaign targeting {segmentMove} using {move.channel} with{" "}
        {
          HTFformLabelMappings[
            move.optimisationKPI as keyof typeof HTFformLabelMappings
          ]
        }{" "}
        optimisation KPI. Destination will be{" "}
        {
          HTFformLabelMappings[
            move.landingPage as keyof typeof HTFformLabelMappings
          ]
        }{" "}
        and the creative score is {(move.creativeX * 100).toFixed(1)}%
      </div>
    );
  };

  const displaySubmitAllMovesButton = () => {
    let display;

    const playerRounds = props?.gameInfo?.players[+playerId]?.rounds;
    const hasRoundStatus = playerRounds[props?.gameInfo?.currentRound]?.status;
    const isACO = props.gameInfo.gameType === GameTypeEnum.ACO;
    const hasStatusWorkin =
      playerRounds[props?.gameInfo?.currentRound]?.status === Status.Working;

    if (hasRoundStatus) {
      if (isACO && hasStatusWorkin) {
        display = true;
      }
    } else if (isACO) {
      display = true;
    }

    return display;
  };

  return (
    <div className="displayMoveTable">
      {displaySubmitAllMovesButton() && (
        <Button
          className="moves-area-round__complete"
          type="button"
          variant="outline-success"
          size="lg"
          style={{ minWidth: "12rem" }}
          onClick={() => updateStatus()}
          disabled={props.moveByIDData?.id}
        >
          Submit all decisions
        </Button>
      )}

      {moves.map((moveWrapper, index) => {
        if (!moveWrapper.moves.length) {
          return null;
        }

        return (
          <div key={index}>
            <h1>Decisions Made </h1>

            <div key={moveWrapper.round}>
              <div className="moves-area-round">
                {props.gameInfo.gameType === GameTypeEnum.ACO && (
                  <span>
                    Round {moveWrapper.round + 1}
                    {moveWrapper.round === props.gameInfo?.currentRound
                      ? " (Current)"
                      : ``}
                  </span>
                )}
              </div>
              <div className="move-container">
                {moveWrapper.moves.length ? (
                  moveWrapper.moves.map((move) => {
                    return (
                      <div
                        className={`move ${
                          move.id === props.moveByIDData?.id && "move-editing"
                        }`}
                        key={move.id}
                      >
                        <span>
                          {move.type === Type.MoveACO
                            ? renderACOContent(move)
                            : renderHTFContent(move)}
                        </span>
                        {moveWrapper.round === props.gameInfo?.currentRound && (
                          <Button
                            type="button"
                            variant="secondary"
                            style={{ minWidth: "6rem" }}
                            onClick={() => editMove(move.id)}
                          >
                            Edit
                          </Button>
                        )}
                        {moveWrapper.round === props.gameInfo?.currentRound &&
                          props.gameInfo.gameType === GameTypeEnum.ACO && (
                            <Button
                              type="button"
                              variant="danger"
                              style={{ minWidth: "6rem" }}
                              onClick={() => deleteMove(move.id)}
                            >
                              Delete
                            </Button>
                          )}
                      </div>
                    );
                  })
                ) : (
                  <div className="move">No Moves inserted for this round </div>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
