import { ReactElement, useState, useMemo, useRef, useEffect } from "react";

import { Button, Col, Container, Row, Table } from "react-bootstrap";

import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { getValueChild, db } from "../../../db/firebase";
import { query, ref, get, child, orderByChild } from "firebase/database";

import countryList from "react-select-country-list";
import Multiselect from "multiselect-react-dropdown";

import { Input } from "../../../elements/Input";
import {
  businessUnitsOptions,
  participantsOptions,
} from "../../../utils/constants";
import { GameInfo } from "types/API/WarGamingAPI";
import _ from "lodash";
import styled from "styled-components";

interface MultipleSelectionsInterface {
  countries: string[];
  businessUnits: string[];
  participants: string[];
  date: string[];
}

export const StyledLabel = styled.label`
  display: flex;
  min-height: 40px;
  margin-top: 30px;
`;

export default function ModeratorPage(): ReactElement {
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit: () => Promise<void> = async () => {
    const gamePath: string = `games/${gameId}`;
    const response = await getValueChild(gamePath);
    if (!response) {
      return setError("Please enter a valid game id");
    }
    return history.push(`/moderate-game/${gameId}`);
  };
  const [gameId, setGameId] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [firstRender, setFirstRender] = useState<boolean>(true);
  const [searchGameTerms, setSearchGameTerms] = useState<string[]>([]);
  const [searchedGames, setSearchedGames] = useState<GameInfo[]>([]);

  const [multipleSelections, setMultipleSelections] =
    useState<MultipleSelectionsInterface>({
      countries: [],
      businessUnits: [],
      participants: [],
      date: [],
    });

  const countriesOptions = useMemo(() => countryList().getData(), []);

  const multiSelect = ({
    type,
    selectedValues,
  }: {
    type: "countries" | "businessUnits" | "participants" | "date";
    selectedValues: string[] | string;
  }) => {
    if (Array.isArray(selectedValues)) {
      const values = selectedValues.map((option: any) => option.value);
      setMultipleSelections({ ...multipleSelections, [type]: values });
    } else {
      setMultipleSelections({
        ...multipleSelections,
        [type]: [selectedValues],
      });
    }
  };

  useEffect(() => {
    if (!firstRender) {
      getAllGames();
    }
    setFirstRender(false);
  }, [multipleSelections]);

  const getAllGames = async () => {
    const dbref = ref(db);
    const snapshots = await get(
      query(child(dbref, "games"), orderByChild("hasMetadata"))
    );
    const games: GameInfo[] = [];
    snapshots.forEach((snapshot) => {
      if (snapshot.exists()) {
        games.push(snapshot.val());
      }
    });

    let gamesThatHaveSearchTerms: GameInfo[] = games.filter(
      (game: GameInfo) => {
        const hasSelections = [];
        let numberOfSelections: string[] = [];
        if (
          typeof game.searchGame === "object" &&
          !Array.isArray(game.searchGame)
        ) {
          for (let key in game.searchGame) {
            if (
              Array.isArray(
                game.searchGame[key as keyof typeof game.searchGame]
              )
            ) {
              if (
                multipleSelections[key as keyof typeof multipleSelections]
                  .length
              ) {
                numberOfSelections.push(key);
                if (
                  game.searchGame[key as keyof typeof game.searchGame].some(
                    (selection: string) =>
                      multipleSelections[
                        key as keyof typeof multipleSelections
                      ].includes(selection)
                  )
                ) {
                  hasSelections.push(key);
                }
              }
            } else {
              return false;
            }
          }
        }
        if (hasSelections.length) {
          return hasSelections.length === numberOfSelections.length;
        }
      }
    );

    gamesThatHaveSearchTerms = _.orderBy(
      gamesThatHaveSearchTerms,
      function (o) {
        return selectionsMatches(o).length;
      },
      "desc"
    );

    setSearchedGames(gamesThatHaveSearchTerms);
  };

  const selectionsMatches = (game: GameInfo, display?: boolean) => {
    let matchingSelections = [];
    if (
      typeof game.searchGame === "object" &&
      !Array.isArray(game.searchGame)
    ) {
      for (let key in game.searchGame) {
        if (
          Array.isArray(game.searchGame[key as keyof typeof game.searchGame])
        ) {
          matchingSelections.push(
            game.searchGame[key as keyof typeof game.searchGame].filter(
              (selection: string) =>
                multipleSelections[
                  key as keyof typeof multipleSelections
                ].includes(selection)
            )
          );
        }
      }
    }
    matchingSelections = matchingSelections.flat();
    if (display) {
      matchingSelections = matchingSelections.map((selection) => {
        const businessUnitsMatch = businessUnitsOptions.filter(
          (option) => option.value === selection
        )[0];
        const participantsMatch = participantsOptions.filter(
          (option) => option.value === selection
        )[0];

        const countriesMatch = countriesOptions.filter(
          (option) => option.value === selection
        )[0];

        if (businessUnitsMatch) {
          return businessUnitsMatch.label;
        }
        if (participantsMatch) {
          return participantsMatch.label;
        }
        if (countriesMatch) {
          return countriesMatch.label;
        }
        return selection;
      });
      return matchingSelections.join(", ");
    }
    return matchingSelections;
  };

  return (
    <div className=" min-h-screen w-100 position-relative text-white ">
      <Container fluid className="position-relative z-index-2 overflow-visible">
        <Row>
          <Col className="justify-content-start d-flex ">
            <div className="d-flex flex-column">
              <h1 className="custom-header">
                Welcome to the facilitator area! In this area you will be able
                to:
              </h1>
              <ol>
                <li className="body-medium">
                  Create a new game ID for each day of the training (Day 2 and
                  Day 3)
                </li>

                <li className="body-medium">Moderate an existing game</li>
              </ol>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="justify-content-between d-flex">
            <Row className="mt-5  change-flex-mobile d-flex flex-column w-25 border-right px-4">
              <Col className="d-flex flex-column align-items-center">
                <h2 className="pl-4 mb-4">Create a new game</h2>
                <Button
                  type="button"
                  variant="outline-success"
                  className="align-self-center align-center-mobile ml-4"
                  onClick={() => history.push("/create-game/")}
                >
                  Create game
                </Button>
              </Col>
            </Row>

            <Row className="mt-5  change-flex-mobile d-flex flex-column w-75 px-4 flex-1 mx-auto">
              <h2 className="mb-4 pl-4">Moderate a known Game ID</h2>
              <Row className=" px-4">
                <Col className="change-flex-mobile mb-5 w-10">
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="d-flex flex-column w-100 justify-content-center align-items-center"
                  >
                    <Col
                      md="auto"
                      className="d-flex flex-column   change-flex-mobile mb-4  w-100 p-0 "
                    >
                      <Col className=" d-flex flex-column w-100 p-0">
                        <input
                          className="w-100 p-2 align-self-start"
                          placeholder="Enter game id"
                          type="text"
                          {...register("gameIDField", {
                            required: "Please enter a game id",
                          })}
                          value={gameId}
                          onChange={(e) => {
                            setGameId(e.target.value);
                            setError("");
                          }}
                        ></input>
                        {errors.gameIDField && (
                          <p>{errors.gameIDField.message}</p>
                        )}
                        {!!error && <p>{error}</p>}
                      </Col>
                    </Col>
                    <Button
                      type="submit"
                      className="align-self-center align-center-mobile btn-black"
                    >
                      Moderate
                    </Button>
                  </form>
                </Col>
              </Row>
              <Row className="change-flex-mobile  px-4 justify-content-between flex-column d-flex">
                <h2 className="mb-4 pl-4">Find a Game ID and Moderate</h2>

                <div className="d-flex justify-content-between">
                  <Col
                    md="auto"
                    className=" d-flex flex-column quarter-width-desktop flex-1"
                  >
                    <StyledLabel>
                      Select the countries of participants
                    </StyledLabel>
                    <Multiselect
                      options={countriesOptions} // Options to display in the dropdown
                      onSelect={(e) =>
                        multiSelect({ type: "countries", selectedValues: e })
                      }
                      onRemove={(e) =>
                        multiSelect({ type: "countries", selectedValues: e })
                      }
                      displayValue="label"
                      style={{
                        chips: {
                          background: "#000 ",
                        },
                        multiselectContainer: {
                          color: "#000",
                          background: "#fff",
                        },
                      }}
                      data-testid="select-countries"
                    />
                  </Col>

                  <Col
                    md="auto"
                    className=" d-flex flex-column quarter-width-desktop flex-1"
                  >
                    <StyledLabel>
                      Select the business units of participants
                    </StyledLabel>
                    <Multiselect
                      options={businessUnitsOptions}
                      onSelect={(e) =>
                        multiSelect({
                          type: "businessUnits",
                          selectedValues: e,
                        })
                      }
                      onRemove={(e) =>
                        multiSelect({
                          type: "businessUnits",
                          selectedValues: e,
                        })
                      }
                      displayValue="label"
                      style={{
                        chips: {
                          background: "#000 ",
                        },
                        multiselectContainer: {
                          color: "#000",
                          background: "#fff",
                        },
                      }}
                      data-
                      data-testid="select-businessUnits"
                    />
                  </Col>
                  <Col
                    md="auto"
                    className=" d-flex flex-column quarter-width-desktop "
                  >
                    <StyledLabel>Select the type of participants </StyledLabel>
                    <Multiselect
                      options={participantsOptions}
                      onSelect={(e) =>
                        multiSelect({ type: "participants", selectedValues: e })
                      }
                      onRemove={(e) =>
                        multiSelect({ type: "participants", selectedValues: e })
                      }
                      displayValue="label"
                      style={{
                        chips: {
                          background: "#000 ",
                        },
                        multiselectContainer: {
                          color: "#000",
                          background: "#fff",
                        },
                      }}
                      data-
                      data-testid="select-participants"
                    />
                  </Col>
                  <Col md="auto" className=" d-flex flex-column ">
                    <StyledLabel>Select the date of the training</StyledLabel>
                    <Input
                      name="date"
                      className="w-100"
                      style={{ height: "39.5px" }}
                      type="date"
                      onChange={(e) =>
                        multiSelect({
                          type: "date",
                          selectedValues: e.target.value,
                        })
                      }
                      data-testid="select-date"
                    />
                  </Col>
                </div>
              </Row>
              <Col className="mt-2 pb-10">
                {searchedGames && searchedGames.length ? (
                  <>
                    <StyledLabel className="leaderboard-heading">
                      Games found
                    </StyledLabel>
                    <Table bordered>
                      <thead>
                        <tr className="tableHeader-moderator">
                          <th>Game Id</th>
                          <th>Matches</th>
                          <th>Game link</th>
                        </tr>
                      </thead>
                      <tbody>
                        {searchedGames.map((game: GameInfo, index: number) => {
                          return (
                            <tr key={index}>
                              <td>{game.uid}</td>
                              <td>{selectionsMatches(game, true)}</td>
                              <td>
                                <Link
                                  to={"/moderate-game/" + game.uid}
                                  key={index}
                                  className="game-links-team"
                                >
                                  Game {game.uid}
                                </Link>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </>
                ) : null}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <div className="h-100 position-fixed w-100 reckitt-gradient top-zero"></div>
    </div>
  );
}
