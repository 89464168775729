import { configureStore } from "@reduxjs/toolkit";
import apiSlice from "./apiSlice";
import authSlice from "./authSlice";
import filtersSlice from "./filtersSlice";
import decodedTokenSlice from "./decodedToken";

export const store = configureStore({
  reducer: {
    apiData: apiSlice,
    filters: filtersSlice,
    auth: authSlice,
    decodedToken: decodedTokenSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
