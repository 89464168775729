import { createNewGame } from "../../../db/db-helpers";
import { ReactElement, useEffect, useState, useMemo, useRef } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useFieldArray, useForm } from "react-hook-form";
import styled from "styled-components";
import countryList from "react-select-country-list";
import Multiselect from "multiselect-react-dropdown";

import {
  GameInfo,
  GameTypeEnum,
  Player,
  Status,
  HtfDefaultSetup,
  AcoDefaultSetup,
} from "../../../types/API/WarGamingAPI";
import { Input } from "../../../elements/Input";
import { Div } from "../../../elements/Div";
import { Link, useHistory } from "react-router-dom";
import {
  businessUnitsOptions,
  participantsOptions,
} from "../../../utils/constants";

export type CreateGame = Omit<GameInfo, "currentRound"> & {
  players: { id: string; name: string; status: string }[];
};

const StyledLabel = styled.label`
  display: block;
`;
const getPlayerDefaultValue = (
  nPlayers?: number | undefined,
  teamNumber?: number | undefined
): {
  id: string;
  name: string;
  status: string;
}[] => {
  return Array.apply(null, Array(nPlayers)).map((t, i) => ({
    id: "",
    name: `${nPlayers ? "Team " + (+i + 1) : "Team " + teamNumber}`,
    status: Status.Working,
  }));
};

export default function NewGame(): ReactElement {
  const history = useHistory();
  const [gameType, setGameType] = useState<string>(GameTypeEnum.HTF);
  const [nPlayers, setNPlayers] = useState<number>(6);
  const [multipleSelections, setMultipleSelections] = useState<{
    countries: string[];
    businessUnits: string[];
    participants: string[];
  }>({
    countries: [],
    businessUnits: [],
    participants: [],
  });
  const [selectionChanged, setSelectionChanged] = useState<
    "countries" | "businessUnits" | "participants" | null
  >(null);
  const [firstRender, setFirstRender] = useState<boolean>(true);
  const [playersDefaultValues, setPlayersDefaultValues] = useState<any>(
    getPlayerDefaultValue(nPlayers)
  );
  const countriesOptions = useMemo(() => countryList().getData(), []);
  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    setValue,
    trigger,
  } = useForm<CreateGame>({
    defaultValues: {
      nPlayers: nPlayers,
      players: playersDefaultValues,
      weeks: "13",
      rounds: 4,
      gameType: GameTypeEnum.HTF,
      sims_per_day: 200,
    },
  });

  const playerAmountWatcher = watch("nPlayers", nPlayers);
  const dateRef = useRef<HTMLInputElement | null>(null);
  const { fields, append, remove, update } = useFieldArray({
    name: "players",
    control,
  });
  const onSubmit = (game: CreateGame) => {
    game.searchGame = {
      countries: multipleSelections.countries,
      businessUnits: multipleSelections.businessUnits,
      participants: multipleSelections.participants,
      date: [dateRef?.current?.value!],
    };
    createNewGame(game).then((gameId) => {
      reset();
      history.push(`/moderate-game/${gameId}`);
    });
  };

  useEffect(() => {
    const defaultSetup: {
      [key: string]: any;
    } = {
      htf: HtfDefaultSetup,
      aco: AcoDefaultSetup,
    };
    setValue("rounds", defaultSetup[gameType].numberOfRounds);
    setValue("weeks", defaultSetup[gameType].numberOfWeeks);
  }, [gameType, setValue]);

  const updateMultipleSelectionsInput = (
    type: "countries" | "businessUnits" | "participants"
  ) => {
    setValue(`metadata.${type}`, multipleSelections[type]);
    trigger(`metadata.${type}`);
  };

  useEffect(() => {
    if (!firstRender) {
      if (selectionChanged) {
        updateMultipleSelectionsInput(selectionChanged);
      }
    }
    setFirstRender(false);
  }, [selectionChanged]);

  useEffect(() => {
    const n = playerAmountWatcher;
    const delta = n - fields.length;
    const sign = Math.sign(delta);

    switch (sign) {
      case 1:
        append(getPlayerDefaultValue(undefined, fields.length + 1));
        break;

      case -1:
        remove(fields.length - 1);
        break;

      default:
        break;
    }
  }, [playerAmountWatcher, fields.length, append, remove]);

  const multiSelect = ({
    type,
    selectedValues,
  }: {
    type: "countries" | "businessUnits" | "participants";
    selectedValues: string[];
  }) => {
    const values = selectedValues.map((option: any) => option.value);
    setMultipleSelections({ ...multipleSelections, [type]: values });
    setSelectionChanged(type);
  };
  const { ref, ...rest } = register("metadata.date", {
    required: "Please select the date of the game",
  });

  return (
    <Container
      fluid
      className="flex-column flex-grow-1 justify-content-center align-items-center"
    >
      <div className="mt-3 mb-3 border-bottom">
        <span className="body-subtitle text-outline-success">
          How to create a new game:
        </span>

        <div className="d-flex flex-column">
          <ol>
            <li>
              Ensure that the correct game type is selected (either HTF for Day
              2 or ACO for Day 3 simulation)
            </li>
            <li>
              Enter the number of teams playing the simulation. Please note, the
              number of teams cannot be changed once the game is created.
            </li>
            <li>
              Add in team names. Please note, default team names are provided
              but we suggest you encourage participants to be creative!
            </li>
            <li>
              Once complete, please press ‘submit’. This will generate a Game
              ID, which we will be used by teams to access the game, so do make
              a note of this as you will share game ID with teams
            </li>
          </ol>
        </div>
      </div>
      <Container fluid>
        <div className="flex-container">
          <h2>Create a new game</h2>
          <Link className="leaderboard-link" to="/leaderboard">
            Leaderboard
          </Link>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Row style={{ minHeight: "8rem" }} className="flex-grow-1 flex">
            <Col
              md="auto"
              className=" d-flex flex-column quarter-width-desktop"
            >
              <StyledLabel htmlFor="search_input">
                Select the countries of participants
              </StyledLabel>
              <Multiselect
                options={countriesOptions} // Options to display in the dropdown
                onSelect={(e) =>
                  multiSelect({ type: "countries", selectedValues: e })
                }
                onRemove={(e) =>
                  multiSelect({ type: "countries", selectedValues: e })
                }
                displayValue="label"
                style={{
                  chips: {
                    background: "#ff007f ",
                  },
                  multiselectContainer: {
                    color: "#ff007f ",
                  },
                }}
                aria-label="cost-input"
              />

              <input
                type="text"
                className="d-none"
                {...register("metadata.countries", {
                  required: "Please select at least one country",
                })}
              />
              <p className="reqFieldMsg">
                {errors?.metadata?.countries?.message}
              </p>
            </Col>

            <Col
              md="auto"
              className=" d-flex flex-column quarter-width-desktop"
            >
              <StyledLabel>
                Select the business units of participants
              </StyledLabel>
              <Multiselect
                options={businessUnitsOptions}
                onSelect={(e) =>
                  multiSelect({ type: "businessUnits", selectedValues: e })
                }
                onRemove={(e) =>
                  multiSelect({ type: "businessUnits", selectedValues: e })
                }
                displayValue="label"
                style={{
                  chips: {
                    background: "#ff007f ",
                  },
                  multiselectContainer: {
                    color: "#ff007f ",
                  },
                }}
              />
              <input
                type="text"
                className="d-none"
                {...register("metadata.businessUnits", {
                  required: "Please select at least one business unit.",
                })}
              />
              <p className="reqFieldMsg">
                {errors?.metadata?.businessUnits?.message}
              </p>
            </Col>
            <Col
              md="auto"
              className=" d-flex flex-column quarter-width-desktop"
            >
              <StyledLabel>Select the type of participants </StyledLabel>
              <Multiselect
                options={participantsOptions}
                onSelect={(e) =>
                  multiSelect({ type: "participants", selectedValues: e })
                }
                onRemove={(e) =>
                  multiSelect({ type: "participants", selectedValues: e })
                }
                displayValue="label"
                style={{
                  chips: {
                    background: "#ff007f ",
                  },
                  multiselectContainer: {
                    color: "#ff007f ",
                  },
                }}
              />
              <input
                type="text"
                className="d-none"
                {...register("metadata.participants", {
                  required: "Please select at least one participant",
                })}
              />
              <p className="reqFieldMsg">
                {errors?.metadata?.participants?.message}
              </p>
            </Col>
            <Col md="auto" className=" d-flex flex-column  ">
              <StyledLabel>Select the date of the training</StyledLabel>
              <Input
                ref={(e) => {
                  ref(e);
                  dateRef.current = e;
                }}
                {...rest}
                name="metadata.date"
                className=" d-flex w-100 mb-2"
                type="date"
                data-testid="date"
              />

              <p className="reqFieldMsg"> {errors?.metadata?.date?.message} </p>
            </Col>
          </Row>
          <Row style={{ minHeight: "8rem" }}>
            <Col md="auto" style={{ minWidth: "19rem" }}>
              <StyledLabel>Number of teams</StyledLabel>
              <Input
                type="number"
                {...register("nPlayers", { required: true, min: 2, max: 10 })}
                min={2}
                max={10}
              />
              {errors.nPlayers && <p>A number of teams is required</p>}
            </Col>
            <Col md="auto" style={{ minWidth: "19rem" }}>
              <StyledLabel>Game type</StyledLabel>
              <div className="form-group--inline">
                <input
                  type="radio"
                  value={GameTypeEnum.HTF}
                  {...register("gameType", { required: true })}
                  onChange={(event) => setGameType(event.target.value)}
                />
                <StyledLabel>Day 2</StyledLabel>
              </div>
              <div className="form-group--inline">
                <input
                  type="radio"
                  value={GameTypeEnum.ACO}
                  {...register("gameType", { required: true })}
                  onChange={(event) => setGameType(event.target.value)}
                />
                <StyledLabel>Day 3</StyledLabel>
              </div>
              {errors.rounds && <p>Game type is required</p>}
            </Col>
          </Row>
          <h2>Name Teams</h2>
          <StyledLabel>Optionally provide custom team names</StyledLabel>
          {fields.map((field, index) => {
            return (
              <Row
                key={`r-${field.id}`}
                className="my-3 d-flex flex-column justify-content-start"
              >
                <div className="d-flex">
                  <Col>
                    <Input
                      type="string"
                      key={field.id}
                      {...register(`players.${index}.name` as const, {
                        required: "Please insert a name for the team",
                      })}
                    />
                  </Col>
                  <Col xs="auto" className="d-flex align-items-center">
                    <Button
                      key={`b${field.id}`}
                      variant="secondary"
                      onClick={() =>
                        update(index, {
                          id: field.id,
                          name: "",
                          status: Status.Working,
                        })
                      }
                      size="sm"
                    >
                      Clear
                    </Button>
                  </Col>
                </div>
                <span className="reqFieldMsg mt-2 d-flex px-3">
                  {" "}
                  {errors?.players?.[index]?.name?.message}{" "}
                </span>
              </Row>
            );
          })}
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id="defaultUnchecked"
              {...register("confirmGameCreation", {
                required: "Please confirm the above selections",
              })}
            />
            <label className="custom-control-label" htmlFor="defaultUnchecked">
              Confirm game creation
            </label>
          </div>{" "}
          <p className="reqFieldMsg">
            {" "}
            {errors?.confirmGameCreation?.message}{" "}
          </p>
          <Button
            type="submit"
            variant="success"
            style={{ minWidth: "12rem", marginTop: "10px" }}
          >
            Submit
          </Button>
        </form>
      </Container>
    </Container>
  );
}
