import "bootswatch/dist/slate/bootstrap.min.css";
import TopNavBar from "components/Navigation/TopNavBar";
import { Route, Switch } from "react-router-dom";
import NewGame from "components/pages/NewGame/NewGame";
import GamePlay from "components/pages/GamePlay/GamePlay";

import MovesPage from "components/pages/Moves/MovesPage";
import Home from "components/pages/Home";
import Leaderboard from "components/pages/Leaderboard";
import Login from "components/pages/Login";
import ProtectedRoute from "components/Auth/ProtectedRoute";
import ModeratorPage from "components/pages/ModeratorPage/ModeratorPage";
import ModerateGame from "components/pages/ModerateGame/ModerateGame";

function App() {
  return (
    <div className="App">
      <>
        <TopNavBar />
        <Switch>
          <Route path="/login">
            <Login />
          </Route>

          <ProtectedRoute
            adminComponent={true}
            path="/moderator-page"
            component={ModeratorPage}
          />

          <ProtectedRoute
            adminComponent={true}
            path="/create-game"
            exact
            component={NewGame}
          />

          <ProtectedRoute
            adminComponent={true}
            path="/moderate-game/:gameId"
            component={ModerateGame}
          />

          <ProtectedRoute path="/leaderboard" component={Leaderboard} />

          <ProtectedRoute
            path="/game/:gameId/team/:playerId"
            component={GamePlay}
          />
          <ProtectedRoute
            path="/moves/:gameId/team/:playerId"
            component={MovesPage}
          />
          <ProtectedRoute path="/" component={Home} />
        </Switch>
      </>
    </div>
  );
}

export default App;
