import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { ssoLogin } from "../../db/firebase";
import Cookies from "universal-cookie";
import jwt from "jwt-decode";
import { useDispatch } from "react-redux";
import { setDecodedToken } from "../../store/decodedToken";
import { useEffect, useState } from "react";
import { auth } from "../../db/firebase";
import { onAuthStateChanged } from "firebase/auth";
import { useHistory, useLocation } from "react-router-dom";
import { isChrome } from "react-device-detect";
const Login = () => {
  const history = useHistory();
  const location = useLocation();

  const dispath = useDispatch();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    console.log(
      "onAuthStateChanged",
      onAuthStateChanged(auth, () => { })
    );
    console.log("auth", auth);

    onAuthStateChanged(auth, async (user) => {
      console.log("onAuthStateChanged", onAuthStateChanged(auth, () => { }));
      console.log("auth", auth);
      console.log("user", user);

      if (user) {
        const idToken = await user.getIdToken();

        dispath(setDecodedToken(jwt(idToken)));
        if (location.search.length) {
          return history.push(location.search.split("=")[1]);
        } else {
          return history.push("/");
        }
      }
      setLoading(false);
    });
  }, []);
  return (
    <>
      {!loading ? (
        <div className="homePageContainer h-screen w-100 position-relative  h-100 bg-black ">
          <Container className="h-100 position-relative " style={{ zIndex: 2 }}>
            <Row className="h-100 g-0 w-100">
              <Col className="justify-content-center justify-items-center d-flex align-items-center">
                <div className="d-flex flex-column">
                  <h1 className="custom-header">
                    Welcome to the DDMM Upskilling Simulation!
                  </h1>
                  <span className="body-medium">
                    Now you and your team will be able to bring theory in
                    practice by stress-testing the effectiveness of your
                    marketing decisions and seeing implication of your decisions
                    on business results!
                  </span>
                  <span className="body-title">Can’t wait to get started?</span>
                  <span className="body-subtitle">
                    Please click ‘Login with SSO’
                  </span>
                  <span className="body-small">
                    If you do not have access to the application, please:
                  </span>
                  <ol className="body-small p-0 px-4">
                    <li>
                      Go to the OKTA request form{" "}
                      <a
                        href="https://rbassist.service-now.com/ssp/?id=sc_cat_item&table=sc_cat_item&sys_id=7c473449db5e9c1482d2ac1848961918"
                        className="hyperlink"
                      >
                        here
                      </a>
                    </li>
                    <li>Select “Assign users to existing application”</li>
                    <li>
                      Select “DDMM Connected Accelerator Level 2 Simulation
                      Game”
                    </li>
                  </ol>
                  {!isChrome && (
                    <span className="chrome-warning">
                      This application requires Google Chrome. Please reopen
                      this application in Google Chrome.
                    </span>
                  )}
                </div>
              </Col>
              <Col className="justify-content-center justify-items-center d-flex align-items-center">
                <Form className="login-form ">
                  <Button variant="dark" block type="button" onClick={ssoLogin}>
                    Login using SSO
                  </Button>
                </Form>
              </Col>
            </Row>
          </Container>
          <div className="h-100 position-absolute w-100 collage-background"></div>
        </div>
      ) : (
        <div>Loading...</div>
      )}
    </>
  );
};

export default Login;
