import { ReactElement, useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import Graphs from "../../../components/Graphs/Graphs";
import { onValue, ref } from "firebase/database";
import { GameTypeEnum, Game } from "../../../types/API/WarGamingAPI";
import congratulations from "../../../assets/pages/congratulations.png";
import { db } from "../../../db/firebase";

export default function GamePlay(): ReactElement {
  let { playerId, gameId } = useParams<{ playerId: string; gameId: string }>();

  const [gameInfo, setGameInfo] = useState<Game>();
  const gameRef = ref(db, `games/${gameId}`);

  useEffect(() => {
    //observer for the current round
    onValue(gameRef, (snapshot) => {
      const game = snapshot.val();
      setGameInfo(game);
    });
  }, []);

  const displayQuerterText = (currentRound: number, game_finished: boolean) => {
    let quarterText;
    switch (currentRound) {
      case 1:
        quarterText = `${currentRound}st `;
        break;
      case 2:
        quarterText = `${currentRound}nd `;
        break;
      case 3:
        quarterText = `${currentRound}rd `;
        break;
      default:
        break;
    }

    if (game_finished) {
      quarterText = `${currentRound + 1}th`;
    }
    return quarterText;
  };
  return (
    <>
      {gameInfo && (
        <>
          <Row>
            <Col>
              {" "}
              {gameInfo.gameType === GameTypeEnum.HTF &&
                gameInfo.currentRound === 1 && (
                  <div className="d-flex justify-content-between container ml-4 mt-4 border-bottom">
                    <img src={congratulations} />
                    <div className="d-flex flex-column ml-4">
                      <span className="game-round-title">
                        Congratulations on completing the first round!
                      </span>
                      <span className="game-round-text">
                        Below you can see your results and how effectively you
                        converted your audience to the ‘Know’ stage versus other
                        teams. This is measured by 100% video views.
                      </span>
                      <div className="game-round-text mt-4">
                        <span>
                          When you have finished reviewing results, please click
                          {"  "}
                        </span>
                        <span className="text-outline-success text-bold font-weight-bold ">
                          {" "}
                          Continue to the Next Round{" "}
                        </span>
                        <span>button.</span>
                      </div>
                    </div>
                  </div>
                )}
              {gameInfo.gameType === GameTypeEnum.HTF &&
                gameInfo.currentRound === 2 && (
                  <div className="d-flex flex-column ml-4 border-bottom">
                    <span className="game-round-title">
                      Here you can see your final scores.
                    </span>
                    <span className="game-round-text">
                      The winning team will be the one who converts the most
                      people across the Intimacy Funnel. Good luck!  
                    </span>
                  </div>
                )}
              {gameInfo.gameType === GameTypeEnum.ACO &&
                gameInfo.currentRound !== 0 && (
                  <div className="d-flex justify-content-between container ml-4 mt-4 border-bottom">
                    <img src={congratulations} />
                    <div className="d-flex flex-column ml-4">
                      <span className="game-round-title">
                        Congratulations on completing your{" "}
                        {displayQuerterText(
                          gameInfo.currentRound,
                          gameInfo.game_finished
                        )}{" "}
                        quarter !
                      </span>
                      <span className="game-round-text">
                        Below you can see your results consisting of weekly net
                        revenue and accumulated net revenue, and how you
                        performed against your competition. Please take the time
                        to analyze and digest your results. Remember, the
                        winning team will be the one with the highest
                        accumulated net revenue across the year.
                      </span>
                      <div className="game-round-text mt-4">
                        <span>
                          When you have finished reviewing results, please click
                          the
                          {"  "}
                        </span>
                        <span className="text-outline-success text-bold font-weight-bold ">
                          {" "}
                          Continue to the Next Round{" "}
                        </span>
                        <span>button.</span>
                      </div>
                    </div>
                  </div>
                )}
            </Col>
          </Row>
          <div className="GamesPlayHeader d-flex flex-column mt-4">
            <div>
              <span className="game-info-title ">Round number:</span>{" "}
              <span className="game-info-text">
                {" "}
                {gameInfo.currentRound + 1}
              </span>
            </div>
            {Boolean(gameInfo.players.length) && (
              <div>
                <span className="game-info-title ">Team:</span>{" "}
                <span className="game-info-text">
                  {" "}
                  {gameInfo?.players[parseInt(playerId)].id}
                </span>
              </div>
            )}

            <div>
              <span className="game-info-title ">Game Type:</span>{" "}
              <span className="game-info-text">
                {" "}
                {gameInfo?.gameType === GameTypeEnum.HTF ? "Day 2" : "Day 3"}
              </span>
            </div>
            <Link
              to={"/moves/" + gameId + "/team/" + playerId}
              className="mt-4"
            >
              <Button
                variant="outline-success"
                size="lg"
                disabled={gameInfo.game_finished}
              >
                Continue To Next Round
              </Button>
            </Link>
          </div>

          <div className="graphs">
            <Graphs data={gameInfo} playerId={playerId} />
          </div>
        </>
      )}
    </>
  );
}
