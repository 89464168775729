import React, { ReactElement, useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
type dialogTypes = {
  title: string;
  bodyText: string;
  confirm: () => void;
  cancel: () => void;
};

type propsType = {
  dialog: object;
};

function ConfirmDialog(props: propsType): ReactElement {
  const { dialog }: propsType = props;

  const [show, setShow] = useState<boolean>(false);
  const [dialogItems, setDialogItems] = useState<dialogTypes | any>();
  useEffect(() => {
    setDialogItems(dialog);
    if (Object.keys(dialog).length) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [dialog]);

  const cancel = () => {
    setDialogItems({});
    setShow(false);
  };

  return (
    <>
      {show && (
        <div
          className="centerBox w-100 h-100  d-flex align-items-center "
          style={{ backgroundColor: "rgba(255,255,255, 0.5)" }}
        >
          <Modal.Dialog className="revenueGraph w-50 ">
            <Modal.Header closeButton onClick={cancel}>
              <Modal.Title>{dialogItems.title}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <p>{dialogItems.bodyText}.</p>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="secondary" onClick={cancel}>
                Cancel
              </Button>
              <Button variant="success" onClick={dialogItems.confirm}>
                {dialogItems.saveButtonText}
              </Button>
            </Modal.Footer>
          </Modal.Dialog>
        </div>
      )}
    </>
  );
}

export default ConfirmDialog;
