import { Spinner } from "react-bootstrap";

interface SpinnerProps {
  text?: string;
}
export default function SpinnerShared({ text = "Loading" }: SpinnerProps) {
  return (
    <div className="d-flex w-100 h-100 fixed-top" style={{ zIndex: 9999 }}>
      <div className="centerBox z-50 justify-items-center d-flex flex-column">
        <Spinner
          animation="border"
          role="status"
          variant="outline-success "
          className="d-flex aling-items-center align-self-center justify-items-center mb-2"
        >
          <span className="visually-hidden">{text}</span>
        </Spinner>
        <span>{text}</span>
      </div>
      <div
        style={{ backgroundColor: "rgba(255,255,255, 0.5)", zIndex: 1000 }}
        className="w-100 h-100 position-absolute"
      ></div>
    </div>
  );
}
