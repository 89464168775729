import { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import {
  Game,
  GameTypeEnum,
  Status,
  Counter,
  Player,
  HtfDefaultSetup,
  AcoDefaultSetup,
} from "../../types/API/WarGamingAPI";
import DisplayMoves from "./DisplayMoves";
import Move from "./Move";
import { onValue, ref } from "firebase/database";
import { db } from "../../db/firebase";
import {
  updatePlayerGameStatus,
  updatePlayerRoundStatus,
} from "../../db/db-helpers";
import girl_vr from "../../assets/pages/girl_vr.png";

export default function Moves() {
  const [movesData, setMovesData] = useState([]);
  const [showAddMoveState, setShowAddMoveState] = useState<boolean>(true);
  const [gameFinished, setGameFinished] = useState<boolean>(true);
  const [currentRound, setCurrentRound] = useState<number>(0);
  const [moveByIDData, setMoveByIDData] = useState<any>();
  const [gameInfo, setGameInfo] = useState<Game>();
  const [counter, setCounter] = useState<Counter>();
  const [currentPlayer, setCurrentPlayer] = useState<Player>();
  const params = useParams<{ gameId: string; playerId: string }>();
  const playerId = parseInt(params.playerId, 10);

  // getAllMoves from Move Fom and send to Display Data component
  const getMovesData = (data: any) => {
    let allMoves: any = [...movesData, data];
    setMovesData(allMoves);
  };

  const getShowAddMoveForm = (data: boolean) => {
    setShowAddMoveState(data);
  };

  const getMoveByIDData = (data: any) => {
    setMoveByIDData(data);
  };

  const onAddMove = async () => {
    setShowAddMoveState(!showAddMoveState);
    setMoveByIDData({});
    // await updatePlayerGameStatus({
    //   gameId: params.gameId,
    //   playerId: params.playerId,
    //   status: Status.Working,
    // });

    if (
      gameInfo?.currentRound !== null &&
      gameInfo?.currentRound !== undefined
    ) {
      //update status of a player at the round level
      await updatePlayerRoundStatus({
        gameId: params.gameId,
        playerId: params.playerId,
        status: Status.Working,
        currentRound: gameInfo.currentRound,
      });
    }
  };

  const gameRef = ref(db, `games/${params.gameId}`);

  useEffect(() => {
    //observer for the current round
    onValue(gameRef, (snapshot) => {
      const game = snapshot.val();
      setGameInfo(game);
      const getCurrentPlayer = game.players[playerId];
      setCurrentPlayer(getCurrentPlayer);

      if (getCurrentPlayer.rounds[game.currentRound]?.status === Status.Ready) {
        setShowAddMoveState(false);
      } else {
        setShowAddMoveState(true);
      }
      const players = game.players;
      setCounter({
        numberOfPlayers: game.nPlayers,
        playersReady: players.filter(
          (player: Player) =>
            player.rounds[game.currentRound]?.status === Status.Ready
        ).length,
      });
      const isGameHtf = game.gameType === GameTypeEnum.HTF;
      const isLastRoundForHtf =
        game.currentRound === HtfDefaultSetup.numberOfRounds;

      if (isGameHtf) {
        if (isLastRoundForHtf) {
          setShowAddMoveState(false);
          setGameFinished(true);
          setCurrentRound(HtfDefaultSetup.numberOfRounds);
        } else {
          setCurrentRound(game.currentRound + 1);
          setGameFinished(false);
        }
      }

      const isLastRoundForAco =
        game.currentRound === AcoDefaultSetup.numberOfRounds;

      if (!isGameHtf) {
        if (isLastRoundForAco) {
          setShowAddMoveState(false);
          setGameFinished(true);
          setCurrentRound(AcoDefaultSetup.numberOfRounds);
        } else {
          setCurrentRound(game.currentRound + 1);
          setGameFinished(false);
        }
      }
    });
  }, []);

  return (
    <div>
      {gameInfo && (
        <>
          {gameInfo.gameType === GameTypeEnum.HTF && (
            <div className="mt-3 mb-3 border-bottom">
              <span className="body-subtitle text-outline-success">
                Now it’s time to make your decisions as you aim to move your
                audience down the Intimacy Funnel and towards purchase!
              </span>
              <div
                className="d-flex rounded container ml-0 p-0 mt-3"
                style={{ height: "160px" }}
              >
                <Col className="reckitt-gradient rounded-left d-flex flex-column align-items-center justify-content-center ">
                  <div className="moves-ready-text">GET READY FOR</div>
                  <div className="moves-rounds-text">3 ROUNDS</div>
                </Col>
                <Col className="lightGreyBackground d-flex flex-column justify-content-center ">
                  <div className="moves-title">Round 1</div>
                  <div className="moves-text">
                    to convert your audience to the ‘Know’ stage
                  </div>
                </Col>{" "}
                <Col className="mediumGreyBackground d-flex flex-column justify-content-center ">
                  <div className="moves-title">Round 2</div>
                  <div className="moves-text">
                    to convert your audience to the ‘Think’ stage
                  </div>
                </Col>{" "}
                <Col className="darkGreyBackground d-flex flex-column justify-content-center ">
                  <div className="moves-title">Round 3</div>
                  <div className="moves-text">
                    to convert your audience to the ‘Do’ stage
                  </div>
                </Col>{" "}
                <Col className="rounded-right overflow-hidden p-0">
                  <div
                    className="d-flex h-100 w-100"
                    style={{
                      backgroundImage: `url(${girl_vr})`,
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center center",
                    }}
                  ></div>
                </Col>
              </div>
              <div className="d-flex flex-column">
                <span className="body-small text-outline-success">
                  Please decide as a team and input:
                </span>
                <span>1. Your audience segment selected in Day 1</span>
                <span>
                  2. Creative score for the round provided by your facilitator
                  previously
                </span>
                <span>3. Destination page</span>
                <span>4. Optimisation KPI</span>
                <span>5. Once complete please click ‘Add decision’</span>
              </div>
            </div>
          )}
          {gameInfo.gameType === GameTypeEnum.ACO && (
            <div className="mt-3 mb-3 border-bottom">
              <span className="body-subtitle text-outline-success">
                Now it’s time to create your marketing campaigns for the
                quarter!  
              </span>

              <div className="d-flex flex-column">
                <span className="body-small text-outline-success">
                  You can create as many campaigns across Meta and YouTube as
                  you wish (given your budget). For each quarter please input:
                </span>
                <span>1. Channel</span>
                <span>2. Ad Format</span>
                <span>3. Destination page</span>
                <span>4. Optimisation KPI</span>
                <span>5. Budget Allocation</span>
              </div>
              <div className="game-round-text mt-4">
                <span>
                  Once completed please click
                  {"  "}
                </span>
                <span className="text-outline-success text-bold font-weight-bold ">
                  {" "}
                  Submit all decisions{" "}
                </span>
                <span>button.</span>
              </div>
            </div>
          )}
          <div className="d-flex flex-column">
            <div>
              <span className="game-info-title ">Round number:</span>{" "}
              <span className="game-info-text"> {currentRound}</span>
            </div>
            {playerId !== undefined && (
              <div>
                <span className="game-info-title ">Team: </span>{" "}
                <span className="game-info-text">
                  {gameInfo.players[playerId].id}
                </span>
              </div>
            )}
            {gameInfo.gameType && (
              <div>
                <span className="game-info-title ">Game Type: </span>{" "}
                <span className="game-info-text">
                  {gameInfo.gameType === GameTypeEnum.HTF ? "Day 2" : "Day 3"}
                </span>
              </div>
            )}
            <h1>Create and edit your moves </h1>
          </div>
        </>
      )}

      {!showAddMoveState &&
      gameInfo?.gameType !== GameTypeEnum.HTF &&
      !gameFinished ? (
        <Button variant="outline-success" size="lg" onClick={() => onAddMove()}>
          Add a decision
        </Button>
      ) : (
        ""
      )}

      <Move
        getMovesData={getMovesData}
        getShowAddMoveForm={getShowAddMoveForm}
        moveByIDData={moveByIDData}
        gameInfo={gameInfo}
        disable={!showAddMoveState}
        counter={counter}
        gameFinished={gameFinished}
        setMoveByIDData={setMoveByIDData}
      />

      {gameInfo && (
        <DisplayMoves
          movesData={movesData}
          getMoveByIDData={getMoveByIDData}
          getShowAddMoveForm={getShowAddMoveForm}
          gameInfo={gameInfo}
          currentPlayer={currentPlayer}
          disable={!showAddMoveState}
          moveByIDData={moveByIDData}
        />
      )}
    </div>
  );
}
