import { ReactElement, useEffect, useState, useMemo } from "react";
import {
  Button,
  Col,
  Container,
  Row,
  Table,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import SpinnerShared from "../../../components/SpinnerShared";
import { Link, useParams } from "react-router-dom";
import { onValue, ref } from "firebase/database";
import { auth, db } from "../../../db/firebase";
import {
  Game,
  Status,
  Player,
  GameTypeEnum,
} from "../../../types/API/WarGamingAPI";
import axios from "axios";
import ConfirmDialog from "../../../components/Modal/ConfirmDialog";

import copySolid from "../../../assets/copy-solid.svg";
import Graphs from "../../../components/Graphs/Graphs";
// import {
//   businessUnitsOptions,
//   participantsOptions,
// } from "../../../utils/constants";
// import countryList from "react-select-country-list";

export default function ModerateGame(): ReactElement {
  const [gameInfo, setGameInfo] = useState<Game>();
  const [players, setPlayers] = useState<Player[]>();
  const [resetOption, setResetOption] = useState<string>("no_option");
  const [showResetError, setShowResetError] = useState<string>();
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [toolTipText, setToolTipText] = useState<string>("");
  const [tooltipIndex, setTooltipIndex] = useState<number>();

  // const countriesOptions = useMemo(() => countryList().getData(), []);

  const [dialog, setDialog] = useState<object>({});

  const params = useParams<{ gameId: string }>();

  const gameRef = ref(db, `games/${params.gameId}`);

  useEffect(() => {
    //observer for the current round
    onValue(gameRef, (snapshot) => {
      const game = snapshot.val();

      setGameInfo(game);

      const players = game.players;

      setPlayers(players);
    });
  }, []);

  const confirmStartSimulation = () => {
    setDialog({
      title: `Start the simulation`,
      bodyText: `Confirm you want to start the simulation for round ${
        gameInfo && gameInfo?.currentRound + 1
      }`,
      confirm: startSimulation,
      saveButtonText: "Start simulation",
    });
  };

  const confirmReset = () => {
    if (resetOption !== "no_option") {
      setDialog({
        title: `Reset`,
        bodyText:
          resetOption === "reset_currentRound"
            ? `Confirm you want to reset round ${
                gameInfo && gameInfo?.currentRound + 1
              }`
            : `Confirm you want to reset the game`,
        confirm: startReset,
        saveButtonText: "Reset",
      });
    } else {
      setShowResetError("Please select an option.");
    }
  };

  const startReset = async () => {
    try {
      setShowLoading(true);
      setDialog({});
      const reset = resetOption === "reset_game" ? 0 : gameInfo?.currentRound;
      const idToken = await auth.currentUser?.getIdToken();
      await axios.get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/reset/${params.gameId}/${reset}`,
        {
          headers: {
            Authorization: idToken,
          },
        }
      );
      setShowLoading(false);
    } catch (error: any) {
      setShowResetError(error.response.data.message);
      setShowLoading(false);
    }
  };

  const startSimulation = async () => {
    const idToken = await auth.currentUser?.getIdToken();

    try {
      setError(undefined);
      setDialog({});
      setShowLoading(true);
      await axios.get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/simulation/${params.gameId}`,
        {
          headers: {
            Authorization: idToken,
          },
        }
      );
      setShowLoading(false);
    } catch (error: any) {
      setShowLoading(false);
      setError(error.response.data.message);
    }
  };

  const generateGameLink = (teamId: number) => {
    return `${window.location.origin}/moves/${gameInfo?.uid}/team/${teamId}`;
  };

  const displayRoundNumberInResults = () => {
    return !gameInfo?.game_finished ? gameInfo?.currentRound : gameInfo?.rounds;
  };

  return (
    <Container fluid className="flex-column flex-grow-1 homePageContainer ">
      <div className="mt-3 mb-3 border-bottom">
        <span className="body-subtitle text-outline-success">
          Welcome to the game facilitation page! On this page you can:
        </span>

        <div className="d-flex flex-column">
          <ul>
            <li>
              Check the status of teams as they play the game. Teams will be
              shown as ‘ready’ or ‘working’ (they still need to submit their
              decisions). Please note, this status automatically updates so
              there is no need to refresh the page
            </li>
            <li>
              Once all teams’ status is set to ready, the simulation can be run
              by clicking ‘Start Simulation’.
            </li>
            <li>
              In case of any issues, the game or round can be restarted by
              selecting an option from the drop-down and clicking ‘reset’.
            </li>
          </ul>
        </div>
      </div>
      <ConfirmDialog dialog={dialog} />
      {showLoading && <SpinnerShared />}
      <Container fluid>
        <div
          className="mt-5  change-flex-mobile d-flex flex-column"
          style={{ padding: "50px" }}
        >
          {gameInfo && (
            <>
              {/* <div className="d-flex justify-content-between mb-4"> */}
              <div>
                <Link to="/leaderboard">
                  <span className="game-info-title ">Leaderboard Link</span>{" "}
                </Link>
                <br></br>
                <span className="game-info-title ">Game ID:</span>{" "}
                <span className="game-info-text"> {params.gameId}</span>
                <br></br>
                <span className="game-info-title ">Round number:</span>{" "}
                <span className="game-info-text">
                  {" "}
                  {gameInfo.currentRound + 1}
                </span>
              </div>
              <div>
                <span className="game-info-title ">Teams:</span>{" "}
                <span className="game-info-text">
                  {" "}
                  {players && players.length}
                </span>
              </div>
              <div>
                <span className="game-info-title ">Game type:</span>{" "}
                <span className="game-info-text">
                  {" "}
                  {gameInfo.gameType === GameTypeEnum.HTF ? "Day 2" : "Day 3"}
                </span>
              </div>
              {/* </div> */}
              {/* <div className="d-flex justify-content-between">
                {gameInfo.metadata.countries.length && (
                  <div>
                    <span className="game-info-title ">Countries</span>{" "}
                    <span className="game-info-text">
                      {" "}
                      {selectionsMatches(gameInfo)?.countries}
                    </span>
                  </div>
                )}
                <div>
                  <span className="game-info-title ">Business units:</span>{" "}
                  <span className="game-info-text">
                    {" "}
                    {players && players.length}
                  </span>
                </div>
                <div>
                  <span className="game-info-title ">Participants:</span>{" "}
                  <span className="game-info-text">
                    {" "}
                    {gameInfo.gameType === GameTypeEnum.HTF ? "Day 2" : "Day 3"}
                  </span>
                </div>
                <div>
                  <span className="game-info-title ">Date:</span>{" "}
                  <span className="game-info-text">
                    {" "}
                    {gameInfo.gameType === GameTypeEnum.HTF ? "Day 2" : "Day 3"}
                  </span>
                </div>
              </div> */}
            </>
          )}
          <div className="leaderboard-heading mt-5">Teams status</div>
          <div className="w-100 align-items-start">
            {players && (
              <Table bordered>
                <thead>
                  <tr className="tableHeader">
                    <th>Team</th>
                    <th>Status</th>
                    <th>Game link</th>
                  </tr>
                </thead>
                <tbody>
                  {players.map((player: any, index: number) => {
                    return (
                      <tr key={index}>
                        <td>{player.id}</td>
                        <td>
                          {gameInfo && player.rounds[gameInfo?.currentRound]
                            ? player.rounds[
                                gameInfo?.currentRound
                              ].hasOwnProperty("status")
                              ? player.rounds[gameInfo?.currentRound].status
                              : Status.Working
                            : Status.Working}
                        </td>
                        <td className="d-flex justify-content-center align-items-center">
                          <a
                            href={`${window.location.origin}/moves/${gameInfo?.uid}/team/${index}`}
                            className="game-links-team"
                            rel="noreferrer"
                          >
                            Team {player.id} game link
                          </a>
                          <OverlayTrigger
                            placement="top"
                            show={
                              toolTipText?.length > 0 && tooltipIndex === index
                            }
                            overlay={
                              toolTipText?.length ? (
                                <Tooltip id="tooltip">{toolTipText}</Tooltip>
                              ) : (
                                <Tooltip id="">{toolTipText}</Tooltip>
                              )
                            }
                          >
                            <img
                              src={copySolid}
                              alt="copy link"
                              width="20"
                              className="copy-to-clipboard"
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  generateGameLink(index)
                                );
                                setToolTipText("Text copied");
                                setTooltipIndex(index);
                              }}
                              onMouseLeave={() => {
                                setToolTipText("");
                              }}
                            />
                          </OverlayTrigger>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            )}
            <Col
              className="d-flex mt-5 justify-content-between flex-wrap change-flex-mobile "
              style={{ minHeight: "8rem" }}
            >
              <div className="d-flex flex-column">
                <Button
                  type="button"
                  variant="dark"
                  className=" align-center-mobile align-self-start mb-4"
                  onClick={() => confirmStartSimulation()}
                  disabled={gameInfo?.game_finished}
                >
                  Start simulation
                </Button>
                {error && <span className="reqFieldMsg">{error}</span>}
              </div>
              <div>
                <Col
                  className="change-flex-mobile flex-grow-1 justify-content-start align-items-start "
                  style={{ minHeight: "8rem" }}
                >
                  <Col
                    md="auto"
                    className="d-flex flex-column flex-grow-1  change-flex-mobile mb-4 "
                  >
                    <Col md="auto" className=" d-flex flex-column ">
                      <select
                        onChange={(e) => {
                          setResetOption(e.target.value);
                          setShowResetError(undefined);
                        }}
                        className=" d-flex flex-grow-1 w-100 mb-2"
                      >
                        <option value="no_option">
                          Select an option to reset
                        </option>
                        <option value="reset_currentRound">
                          Reset current round
                        </option>
                        <option value="reset_game">Reset game</option>
                      </select>
                      {showResetError && (
                        <p className="reqFieldMsg">{showResetError}</p>
                      )}
                    </Col>
                  </Col>
                  <Button
                    type="button"
                    variant="outline-success"
                    className="align-center-mobile mb-4"
                    onClick={confirmReset}
                  >
                    Reset
                  </Button>
                </Col>
              </div>
            </Col>
          </div>
        </div>
        {gameInfo?.currentRound && players ? (
          <div
            className="graphs text-center border-top-custom"
            style={{ paddingTop: "40px" }}
          >
            <span className="game-info-title ">
              Results for round {displayRoundNumberInResults()}
            </span>{" "}
            <Graphs data={gameInfo} playerId={players[0].id} />
          </div>
        ) : null}
      </Container>
    </Container>
  );
}
