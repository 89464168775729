import { ref, set, update } from "firebase/database";
import { Move, HTFMove, ACOMove } from "../../types/API/WarGamingAPI";
import { db } from "../../db/firebase";
export const pushMovesData = async (
  move: ACOMove | HTFMove | Move,
  gameId: string,
  round: number,
  playerId: string,
  dbMoveId?: string
): Promise<void> => {
  if (!gameId || round === undefined) {
    return;
  }

  const gamesDB = ref(
    db,
    `games/${gameId}/players/${playerId}/rounds/${round}/moves/${move.id}`
  );

  // on edit move update the existing record
  if (dbMoveId) {
    update(gamesDB, move);
  } else {
    // on add move create new recoed
    set(gamesDB, move);
  }
};

export const ACOformLabelMappings = {
  website_home_page: "Brand Website - Product Range Page",
  website_product_page: "Brand Website – Product Page",
  amazon_product_page: "Product Page on Amazon",
  youtube: "YouTube",
  facebook: "Meta",
  CPM: "Cost per Mille",
  CPCV: "Cost per Completed View",
  CPA: "Cost per Landing Page Action",
  CPLC: "Cost Per Link Click",
  CPC: "Cost per Outbound Click",

  video_15s_instream: "15s video (in-stream)",
  video_6s_infeed: "6s video (in feed)",
  video_6s_stories: "6s video (Instagram stories)",
  video_6s_bumper: "6s video (bumper)",
  video_15s_skip: "15s video (skip)",
  video_15s_non_skip: "15s video (non-skip)",
};

export const HTFformLabelMappings = {
  CPCV: "Cost per Completed View",
  CPA: "Cost per Landing Page Action",
  CPLC: "Cost Per Link Click",
  CPC: "Cost per Outbound Click",
  CPLV: "Cost per Landing Page Views",
  CPM: "Cost Per Mille",
  CPRC: "Cost per Retailer Link Click",
  amazon_brand_page: "Amazon Brand Store Page",
  amazon_product_page: "Product Page on Amazon",
  website_home_page: "Brand Website - Product Range Page",
  website_product_page: "Brand Website - Product Page",
  brand_social_pages: "Brand Social Pages",
};
