const businessUnitsOptions = [
  {
    label: "Health",
    value: "health",
  },
  {
    label: "Hygiene",
    value: "hygiene",
  },
  {
    label: "Nutrition",
    value: "nutrition",
  },
  {
    label: "RB eRB & Greater China",
    value: "rb_erb_greater_china",
  },
  {
    label: "RB Corporate",
    value: "rb_corporate",
  },
];

const participantsOptions = [
  {
    label: "IMEX / Marketing",
    value: "imex_marketing",
  },
  {
    label: "CDO / Global Category",
    value: "cdo_global_category",
  },
  {
    label: "Marketing Excellence",
    value: "marketing_excellence",
  },
  {
    label: "Other departments",
    value: "other_departments",
  },
  {
    label: "External agency partners",
    value: "external_agency_partners",
  },
];

export { businessUnitsOptions, participantsOptions };
