/**
 * Schema for the user, game and results output data for the wargaming application
 */
export default interface WarGamingAPI {
  /**
   * Map of all games, where the keys are the unique IDs of the games
   */
  games?: Games;
  // organisations?: Organisations;
  // rooms?: Rooms;
  // /**
  //  * All signed up users, where the key is the user email ID
  //  */
  // users?: Users;
}

/**
 * Map of all games, where the keys are the unique IDs of the games
 */
export interface Games {
  [gameId: string]: Game;
}
export enum HtfDefaultSetup {
  numberOfRounds = 3,
  numberOfWeeks = "13",
}

export enum AcoDefaultSetup {
  numberOfRounds = 4,
  numberOfWeeks = "13",
}

//day 2 is htf
//day 3 is aco
export type GameType = "htf" | "aco";

export enum GameTypeEnum {
  HTF = "htf",
  ACO = "aco",
}
export interface GameInfo {
  currentRound: number;
  days: number;
  weeks: string;
  nPlayers: number;
  gameType: GameType;
  sims_per_day: number;
  metadata: {
    countries: string[];
    date: string;
    participants: string[];
    businessUnits: string[];
  };
  searchGame: {
    countries: string[];
    date: string[];
    participants: string[];
    businessUnits: string[];
  };
  hasMetadata: boolean;
  confirmGameCreation?: boolean;
  /**
   * Number of rounds in game
   */
  rounds: number;
  uid: string;
}

/**
 * A single wargame
 */
export interface Game extends GameInfo {
  /**
   * Players invited to join the game
   */
  players: Player[];
  /**
   * Results for the game
   */
  results?: Rounds;

  // costMetrics?: {
  //   CostperACOTotalPointperDay: number;
  //   MaxACOTotalDeltaPerDay: number;
  // };
  gameState?: any;
  uid: string;
  game_finished: boolean;
}

export type CustomerGroup = "high" | "low" | "middle";
export type StoreType = "local" | "online" | "super";
export type Region = "rural" | "suburban" | "urban";

/**
 * A single move played by a player in a round of the game
 */
export interface Move {
  amount: number;
  customerGroup?: CustomerGroup[];
  endWeek: string;
  id: string;
  startWeek: string;
  type: Type;
  investment: number;
  segment: string;
  channel: string;
  creativeX: number;
  landingPage: string;
  optimisationKPI: string;
  adFormat: string;
}

export type MoveOutcome = "unset" | "success" | "overshot";

export interface HTFMove {
  id: string;
  //investment: number;
  segment: string;
  channel: string;
  landingPage: string;
  creativeX: number;
  type: Type;
  optimisationKPI: string;
}

export interface ACOMove {
  id: string;
  type?: Type;
  channel: string;
  adFormat: string;
  landingPage: string;
  optimisationKPI: string;
  investment: number;
}

export enum Category {
  All = "all",
  // Biscuits = "biscuits",
  // Meat = "meat",
}

export enum Type {
  Logistics = "logistics",
  Price = "price",
  Marketing = "marketing",
  MoveACO = "aco",
  MoveHTF = "htf",
}

export enum Level {
  Urban = "urban",
  Suburban = "suburban",
  Rural = "Rural",
}

export enum HTFFields {
  Segment = "segment",
  Channel = "channel",
  LandingPage = "landingPage",
}

export interface MovesCollection {
  [moveId: string]: HTFMove | Move | ACOMove;
}
export interface Moves {
  moves: MovesCollection;
}
export type PlayerRound = {
  moves?: { [moveId: string]: HTFMove | Move | ACOMove };
  status?: string;
};
export interface Player {
  /**
   * Current player state in Edit Moves
   */
  id: string;
  status?: Status;
  // role?: GameRole;
  rounds: PlayerRound[];
}

/**
 * Current player state in Edit Moves
 */
export enum Status {
  Ready = "ready",
  Working = "working",
}

export enum GameRoundWeeksDefaultSetup {
  Ready = "ready",
  Working = "working",
}
export interface Counter {
  numberOfPlayers: number;
  playersReady: number;
}

/**
 * Results for the game
 */
export interface Results {
  /**
   * An object containing results grouped into rounds of the game, where the keys are round +
   * <roundNumber>
   */
  rounds: Rounds;
}

/**
 * An object containing results grouped into rounds of the game, where the keys are round +
 * <roundNumber>
 */
export interface Rounds {
  rounds: [RoundNew];
}

/**
 * Aggregated metric outputs for a single round of a game
 *
 */
export interface Round {
  /**
   * Metrics produced by the model
   */
  metrics: Metrics;
  /**
   * Individual transaction level outputs for a single round of a game
   */
  transactions?: Transaction[];

  /**
   * End date of the round
   */
  end_date: string;
}

export type MetricsKeys =
  | "balance"
  | "sales"
  | "ACO_MoveCost"
  | "ACO_ScoreCost"
  | "ACO_Infrastructure"
  | "ACO_SupplierScore"
  | "ACO_SupplyChain"
  | "ACO_Tech"
  | "ACO_Total";

export const ACOMetricLabels = new Map<string, string>([
  ["ACO_Infrastructure", "Infrastructure"],
  ["ACO_SupplierScore", "Supplier Score"],
  ["ACO_SupplyChain", "Supply Chain"],
  ["ACO_Tech", "Technology"],
]);

/**
 * Metrics produced by the model
 */
export interface Metrics {
  balance: Metric;
  sales: Metric;
  ACO_Infrastructure: Metric;
  ACO_SupplierScore: Metric;
  ACO_SupplyChain: Metric;
  ACO_Tech: Metric;
  ACO_Total: Metric;
  ACO_MoveCost: Metric;
  ACO_ScoreCost: Metric;
  eoy_metrics: {
    player: {
      [key: string]: EndOfYearMetrics;
    };
  };
}

/**
 * Metric produced by the model, where the key is the metricId
 */
export interface Metric {
  /**
   * Id of the metric
   */
  // id: MetricID;
  /**
   * Collection of players in the game, where the keys are the player email IDs
   */
  player: PlayersResults;
}

/**
 * Id of the metric
 */
export enum MetricID {
  Balance = "balance",
  Sales = "sales",
}

/**
 * Collection of players in the game, where the keys are the player email IDs
 */
export interface PlayersResults {
  [playerId: string]: (undefined | PlayerResult)[];
}

export interface PlayerResult {
  /**
   * Human readable name of the player
   */
  // name: string;
  /**
   * Metric values per player per day, where the keys are the day number
   */
  day: number;
  value: number;
}

export interface Organisations {
  [organisationId: string]: Organisation;
}

/**
 * Group of users and games associated with a parent organisation
 */
export interface Organisation {
  name?: string;
  /**
   * List of room IDs associated with the organisation
   */
  rooms?: string[];
  /**
   * Users belonging to the organisation and their roles
   */
  users?: OrgUser[];
}

export interface OrgUser {
  roles?: Role[];
  id: string;
}

/**
 * organisationAdmin can edit organisation level data. manageUser can add and remove user
 * IDs on the organisation. createRoom can create new rooms which the user will then become
 * the owner/admin of.
 */
export enum Role {
  CreateRoom = "createRoom",
  ManageUser = "manageUser",
  OrganisationAdmin = "organisationAdmin",
}

export enum GameRole {
  GameAdmin = "gameAdmin",
  Participant = "participant",
}

/**
 * Keys are the user email IDs
 */
export interface UserValue {
  roles?: Role[];
}

export interface Rooms {
  [roomId: string]: Room;
}

/**
 * A group of players and games
 */
export interface Room {
  /**
   * List of game IDs associated with the room
   */
  games?: string[];
  /**
   * list of player email IDs belonging to the room
   */
  players?: string[];
}

/**
 * All signed up users, where the key is the user email ID
 */
export interface Users {
  [userId: string]: User;
}

/**
 * A single user
 */
export interface User {
  /**
   * Games the user has taken part in, where each item is a game unique ID
   */
  games?: string[];
  id: string;
  name: string;
  organisationID: string;
  /**
   * Rooms the user has access to, where each item is a room unique ID
   */
  rooms?: string[];
}

export interface Transaction {
  count: number;
  brand: string;
  day: number;
  basket_cost_mean: number;
  basket_value_mean: number;
  basket_size: number;
  customer_group: string;
  region: string;
  store_type: string;
}

export interface EndOfYearMetrics {
  revenue: number;
  basket_cogs: number;
  gross_margin: number;
  opex: number;
  ebitda: number;
  avg_basket_value: number;
  vol_of_basket: number;
  share_of_basket_vol: number;
  capital_available: number;
  capital_spent: number;
  capital_rolled_over: number;
  basket_cost: number;
  aco_score_cost: number;
  carbon_tax: number;
  revenue_pc: number;
  basket_cogs_pc: number;
  gross_margin_pc: number;
  opex_pc: number;
  ebitda_pc: number;
  avg_basket_value_pc: number;
  vol_of_basket_pc: number;
  share_of_basket_vol_pc: number;
  capital_available_pc: number;
  capital_spent_pc: number;
  capital_rolled_over_pc: number;
  basket_cost_pc: number;
  aco_score_cost_pc: number;
  carbon_tax_pc: number;
}

/**
 * An object containing results grouped into players of the game, where the keys are player +
 * <playerNumber>
 */
export interface Players {
  [playerId: string]: PlayerNew;
}
export interface PlayerNew {
  /**
   * Metrics produced by the model
   */
  metrics: {
    revenue: {
      value: number;
      week: number;
    };
  };
}

export interface RoundNew {
  end_date: string;
  players: [any];
  start_date: string;
}
