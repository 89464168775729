import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";

export type Token = {
  email: string;
};

interface decodedTokenState {
  token: Token | null;
}

const initialState: decodedTokenState = {
  token: null,
};

export const decodedTokenSlice = createSlice({
  name: "decodedToken",
  initialState,
  reducers: {
    setDecodedToken: (state, action: PayloadAction<Token | null>) => {
      state.token = action.payload;
    },
  },
});

export const { setDecodedToken } = decodedTokenSlice.actions;

export const selectDecodedToken = (state: RootState) => state.decodedToken;

export default decodedTokenSlice.reducer;
