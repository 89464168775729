import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Game, GameTypeEnum } from "../types/API/WarGamingAPI";
import { RootState } from "./store";
import { getValueChild } from "../db/firebase";
type APIState = {
  status: "idle" | "loading" | "succeeded" | "failed";
  error?: null | string;
};

export type GameDataState = APIState & { data: Game };

const initialState: GameDataState = {
  data: {
    currentRound: 0,
    days: 0,
    nPlayers: 0,
    players: [],
    rounds: 0,
    weeks: "0",
    gameType: GameTypeEnum.HTF,
    sims_per_day: -1,
    uid: "",
    game_finished: false,
    metadata: {
      countries: [],
      date: new Date().toISOString(),
      businessUnits: [],
      participants: [],
    },
    searchGame: {
      countries: [],
      date: [""],
      businessUnits: [],
      participants: [],
    },
    hasMetadata: false,
  },
  status: "idle",
  error: null,
};

export const fetchGame = createAsyncThunk(
  "api/fetchGame",
  async (gameId: string) => {
    const gamePath: string = `games/${gameId}`;
    const response = await getValueChild(gamePath);

    return response as any;
  }
);

const gameDataSlice = createSlice({
  name: "gameData",
  initialState,
  reducers: {
    reset() {
      return { ...initialState };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchGame.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchGame.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Add any fetched posts to the array
        state.data = { ...state.data, ...action.payload };
      })
      .addCase(fetchGame.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message ? action.error.message : "UNKNOWN";
      });
  },
});

export const { reset } = gameDataSlice.actions;

export default gameDataSlice.reducer;

export const selectApiData = (state: RootState) => state.apiData.data;
export const selectApiStatus = (state: RootState) => state.apiData.status;
export const selectApiError = (state: RootState) => state.apiData.error;
