import { Route, Redirect, Router } from "react-router-dom";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { selectDecodedToken } from "../../store/decodedToken";
import { useSelector } from "react-redux";
const ProtectedRoute = ({
  adminComponent,
  component: Component,
  ...rest
}: any) => {
  const prevLocation = useLocation();
  const [isAdminAuth, setIsAdminAuth] = useState<boolean>(false);
  const [isAuthed, setIsAuth] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const token = useSelector(selectDecodedToken);
  const moderatorEmails: string | undefined =
    process.env.REACT_APP_MODERATOR_EMAILS;

  useEffect(() => {
    const decodedToken = token.token;

    const emailDomain = decodedToken?.email.split("@")[1] || "";

    if (decodedToken) {
      if (
        decodedToken !== undefined &&
        moderatorEmails
          ?.split(",")
          .map((email) => email.trim())
          .includes(decodedToken?.email)
      ) {
        setIsAdminAuth(true);
        setIsLoading(false);
      } else {
        setIsAdminAuth(false);
        setIsLoading(false);
      }

      if (
        decodedToken !== undefined &&
        process.env.REACT_APP_EMAIL_DOMAIN?.split(",")
          .map((domain) => domain.trim())
          .includes(emailDomain)
      ) {
        setIsAuth(true);
        setIsLoading(false);
      } else {
        setIsAuth(false);
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  }, [token, moderatorEmails]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if ((isAuthed !== undefined && !isAuthed) || isAuthed === undefined) {
    return (
      <Redirect
        to={{
          pathname: `/login`,
          search: `?redirectTo=${prevLocation.pathname}`,
        }}
      />
    );
  }
  if (isAuthed && !isAdminAuth && adminComponent) {
    return (
      <Redirect
        to={{
          pathname: `/`,
        }}
      />
    );
  }

  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

export default ProtectedRoute;
