import { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import {
  HTFMove,
  HtfDefaultSetup,
  Move,
  Status,
  Type,
} from "../../types/API/WarGamingAPI";
import { v4 as uuidv4 } from "uuid";
import { StyledLabel } from "./Move";
import { FormProps, HTFFormData } from "./Types";
import { pushMovesData } from "./Utils";
import {
  updatePlayerGameStatus,
  updatePlayerRoundStatus,
} from "../../db/db-helpers";

import segment_icon from "../../assets/pages/segment_icon.png";
import creative_score_icon from "../../assets/pages/creative_score_icon.png";
import landing_page_icon from "../../assets/pages/landing_page_icon.png";
import optimization_icon from "../../assets/pages/optimization_icon.png";

export const generateHTFMove = (
  payload: HTFFormData,
  dbMoveId?: string
): HTFMove => ({
  id: dbMoveId || uuidv4().slice(0, 5),
  type: payload.type,
  //investment: +payload.investment,
  segment: payload.segment,
  channel: payload.channel,
  landingPage: payload.landingPage,
  creativeX: payload.creativeX / 100,
  optimisationKPI: payload.optimisationKPI,
});

export type HTFValues = {
  segment: string;
  channel: string;
  type: string;
  //investment: number,
  creativeX: number;
  landingPage: string;
  optimisationKPI: string;
};

export const getHTFDefaultValues = (): HTFValues => ({
  segment: "young_singles",
  channel: "facebook",
  type: Type.MoveHTF,
  //investment: 0,
  creativeX: 0.0,
  landingPage: "website_home_page",
  optimisationKPI: "CPCV",
});

export const HTFForm = (props: FormProps<Move>) => {
  const params = useParams<{
    gameId: string;
    playerId: string;
  }>();

  const [firstRoundSelection, setFirstRoundSelection] = useState<string>();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<any>({
    defaultValues: getHTFDefaultValues(),
  });

  useEffect(() => {
    if (props.gameInfo.currentRound !== 0) {
      const firstRoundMove: any =
        props.gameInfo.players[+params.playerId].rounds[0].moves;

      if (firstRoundMove) {
        const keyFirstRoundMove = Object.keys(firstRoundMove)[0];
        setFirstRoundSelection(firstRoundMove[keyFirstRoundMove].segment);
      }
    }
  }, [props.gameInfo]);

  useEffect(() => {
    if (props.moveByIDData?.id) {
      let moveData: HTFMove | Move;

      if (props.moveByIDData!.type === Type.MoveHTF) {
        moveData = props.moveByIDData as HTFMove;
      } else {
        moveData = props.moveByIDData as Move;
      }
      if (props.moveByIDData!.type === Type.MoveHTF) {
        moveData = props.moveByIDData as HTFMove;
      } else {
        moveData = props.moveByIDData as Move;
      }

      setValue("segment", props.moveByIDData!.segment);
      setValue("channel", props.moveByIDData!.channel);
      setValue("landingPage", props.moveByIDData!.landingPage);
      setValue("creativeX", props.moveByIDData!.creativeX * 100);
      setValue("optimisationKPI", props.moveByIDData!.optimisationKPI);
      setValue("investment", +props.moveByIDData!.investment);
    }
  }, [props.moveByIDData, setValue]);

  const onSubmit = async (d: any) => {
    if (firstRoundSelection) {
      d.segment = firstRoundSelection;
    }
    let move = generateHTFMove(d, props.moveByIDData?.id);

    pushMovesData(
      move,
      params.gameId,
      props.gameInfo.currentRound,
      params.playerId,
      props.moveByIDData?.id
    );

    props.getMovesData(move);
    props.getShowAddMoveForm(!props.getShowAddMoveForm);

    //update status of a player at the game level
    if (props.gameInfo.currentRound === HtfDefaultSetup.numberOfRounds - 1) {
      await updatePlayerGameStatus({
        gameId: params.gameId,
        playerId: params.playerId,
        status: Status.Ready,
      });
    }

    //update status of a player at the round level
    await updatePlayerRoundStatus({
      gameId: params.gameId,
      playerId: params.playerId,
      status: Status.Ready,
      currentRound: props.gameInfo.currentRound,
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row style={{ minHeight: "12rem" }} className="pt-4">
        <Col
          md="auto"
          style={{ minWidth: "19rem" }}
          className="d-flex justify-content-start flex-column"
        >
          <img src={segment_icon} width={24} alt="segment" className="mb-2" />
          <StyledLabel>Segment</StyledLabel>
          <select
            {...register("segment")}
            disabled={
              props.disable ||
              (firstRoundSelection && props.gameInfo.currentRound !== 0)
                ? true
                : false
            }
            {...(firstRoundSelection &&
              props.gameInfo.currentRound !== 0 && {
                value: firstRoundSelection,
              })}
            aria-label="segment"
          >
            <option value="young_singles">Young Singles</option>
            <option value="young_families">Young Families</option>
            <option value="established_families">Established Families</option>
          </select>
        </Col>

        <Col
          md="auto"
          style={{ width: "19rem" }}
          className="d-flex justify-content-start flex-column"
        >
          <img
            src={creative_score_icon}
            width={24}
            alt="creative score"
            className="mb-2"
          />
          <StyledLabel>Creative Score (%)</StyledLabel>
          <input
            type="number"
            {...register("creativeX", {
              required: "Creative score must be equal or greater than 10",
              min: 10.0,
              max: 100.0,
            })}
            min={10}
            max={100.0}
            step={0.1}
            disabled={props.disable}
            aria-label="creativeX"
          />
          <span className="reqFieldMsg"> {errors?.creativeX?.message} </span>
        </Col>

        <Col
          md="auto"
          style={{ minWidth: "19rem" }}
          className="d-flex justify-content-start flex-column"
        >
          <img
            src={landing_page_icon}
            width={24}
            alt="creative score"
            className="mb-2"
          />
          <StyledLabel>Landing Page</StyledLabel>
          <select {...register("landingPage")} disabled={props.disable}>
            <option value="amazon_brand_page"> Amazon Brand Store Page</option>
            <option value="amazon_product_page"> Product Page on Amazon</option>
            <option value="website_home_page">
              Brand Website - Product Range Page
            </option>
            <option value="website_product_page">
              Brand Website - Product Page
            </option>
            <option value="brand_social_pages">Brand Social Pages</option>
          </select>
        </Col>

        <Col
          md="auto"
          style={{ minWidth: "19rem" }}
          className="d-flex justify-content-start flex-column"
        >
          <img
            src={optimization_icon}
            width={24}
            alt="creative score"
            className="mb-2"
          />
          <StyledLabel>Optimisation KPI</StyledLabel>
          <select {...register("optimisationKPI")} disabled={props.disable}>
            <option value="CPCV">Cost per Completed View</option>
            <option value="CPLC">Cost Per Link Click </option>
            <option value="CPM">Cost Per Mille</option>
            <option value="CPC">Cost Per Outbound Click</option>
            <option value="CPRC">Cost Per Retailer Link Click</option>
          </select>
        </Col>
      </Row>

      <Button
        type="submit"
        variant="dark"
        style={{ minWidth: "12rem" }}
        disabled={props.disable}
      >
        {props.moveByIDData?.id ? "Update decision" : "Add Decision"}
      </Button>
    </form>
  );
};
