import { ReactElement, useEffect, useState } from "react";
import { Navbar } from "react-bootstrap";
import logo from "assets/watermark.png";
import { Link, useLocation } from "react-router-dom";

export default function TopNavBar(): ReactElement {
  // const userId = useAppSelector(selectUserId);
  const location = useLocation();
  const [pathName, setPathname] = useState<string>("");
  useEffect(() => {
    setPathname(location.pathname);
  }, [location]);
  return (
    <>
      <div>
        <Navbar
          variant="light"
          className={`custom-nav ${
            pathName === "/moderator-page" && "border-bottom border-white "
          }`}
        >
          <Link to="/" className="d-flex w-100">
            <Navbar.Brand className="custom-nav-bar">
              <img
                alt="Reckitt logo"
                src={logo}
                width="100px"
                height="auto"
                className="watermark-reckitt"
              />
              <span className="title-nav-bar">
                DDMM Simulation-based Bootcamp
              </span>
              <div className="powered-by">
                <span>
                  <em>powered by </em>
                </span>
                <span>The Marketing Academy </span>
              </div>
            </Navbar.Brand>
          </Link>
          <Navbar.Toggle />
          {/* <Navbar.Collapse className="justify-content-end">
        <Dropdown>
          <Dropdown.Toggle variant="secondary" size="sm">
            Org, room
        </Dropdown.Toggle>
          <Dropdown.Menu alignRight style={{ width: '100%' }} >
            <Dropdown.Header>Current org name</Dropdown.Header>
            <Dropdown.Item href="#/action-1">Room 1</Dropdown.Item>
            <Dropdown.Item href="#/action-1">Room 2</Dropdown.Item>
            <Dropdown.Item href="#/action-1">Administer rooms</Dropdown.Item>
            <Dropdown.Item href="#/actions-1">Administer org</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Header>Org 2 name</Dropdown.Header>
            <Dropdown.Item href="#/action-1">Room 1</Dropdown.Item>
            <Dropdown.Item href="#/action-1">Room 2</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item href="#/action-1">Logout</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown>
          <Dropdown.Toggle variant="secondary" size="sm">
            {userId}
          </Dropdown.Toggle>
          <Dropdown.Menu alignRight style={{ width: '100%' }} >
            <Dropdown.Item href="#/action-1">Settings</Dropdown.Item>
            <Dropdown.Item href="#/action-1">Logout</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

      </Navbar.Collapse> */}
        </Navbar>
      </div>
    </>
  );
}
